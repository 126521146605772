import type { AxiosInstance } from "axios";
import axiosNoAuth, { AxiosError } from "axios";

import type { AddOrRemovePeopleEventType } from "src/components/job/JobAssignment";
import { isNotNullish } from "src/utils/isNotNullish";

import { marketplaceAPIURI } from "./marketplaceAPIURI";
import type { Appointment } from "./models/Appointment";
import type { Contact } from "./models/Contact";
import type { EntityTag, EntityTagCount } from "./models/EntityTag";
import type {
  CancellationResidentAction,
  DeleteInvoiceArgs,
  GetAppointmentsInput,
  GetAvailabilityInput,
  GetInvoiceDetailsForJobInput,
  GetInvoicesInput,
  GetJobEventsInput,
  GetJobInput,
  GetJobsheetInput,
  GetJobsInput,
  GetQuoteInput,
  GetTradespeopleInput,
  GetTradespersonEventsInput,
  JobInput,
  MarketplaceAPILoginInput,
  MSPartnerLoginInput,
  PinJobArgs,
  RefreshAuthTokensInput,
  ScheduleJobArgs,
  UpdateInvoiceArgs,
  UpdateJobArgs,
  UpdateJobSheetArgs,
  UpdateQuoteArgs,
  UpdateTradespersonArgs,
  ValidatePostcodeInput,
} from "./models/inputs";
import type { Invoice, InvoicingDetails, UploadRequestResponse } from "./models/Invoice";
import type { Job, JobEvent } from "./models/Job";
import type { JobSheet } from "./models/JobSheet";
import type { ManagedRate } from "./models/ManagedRate";
import type { Partner } from "./models/Partner";
import type { PartnerDomain } from "./models/PartnerDomain";
import type {
  ImpersonateTradespersonResponse,
  PartnerTradesperson,
  TradespersonEvent,
} from "./models/PartnerTradesperson";
import type { Product } from "./models/Product";
import type { Quote } from "./models/Quote";
import type {
  GetAvailabilityResponse,
  GetTradespeopleResponse,
  InvoicesResponse,
  JobsEventsResponse,
  JobsResponse,
  MSPartnerLoginResponse,
  RefreshAuthTokensResponse,
  TradespersonEventsResponse,
} from "./models/responses";
import type { Tag, TagEntityType } from "./models/Tag";
import { multiservicesURI } from "./multiservicesURI";
import type {
  Company,
  CreateCompany,
  CreateTradespersonCompany,
  GetCompaniesResponse,
  GetCompanyPartnersResponse,
  GetTradespersonCompaniesResponse,
  TradespersonCompany,
  Tradesperson,
  UpdateCompany,
  UpdateTradespersonCompany,
  GetTradespersonsResponse,
  UpdateCompanyPartner,
  CompanyPartner,
  CreateCompanyPatner,
} from "./models/Companies";
import type { PermissionGroup } from "./models/Permissons";

export const loginToMarketplaceAPI = async (
  axios: AxiosInstance,
  input: MarketplaceAPILoginInput,
) => {
  const response = await axios.get<{ access_token: string }>(`${marketplaceAPIURI}/api/login`, {
    auth: input,
  });
  return response.data;
};

export const loginToMS = async (axios: AxiosInstance, input: MSPartnerLoginInput) => {
  const response = await axios.post<MSPartnerLoginResponse>(
    `${multiservicesURI}/api/partner/v1/token-auth`,
    input,
  );
  return response.data;
};

export const refreshAuthTokens = async (
  axios: AxiosInstance,
  { refreshToken }: RefreshAuthTokensInput,
) => {
  const response = await axios.post<RefreshAuthTokensResponse>(
    `${multiservicesURI}/api/partner/v1/refresh-token`,
    { refreshToken },
  );
  return response.data;
};

export const getJob = async (axios: AxiosInstance, { id }: GetJobInput) => {
  if (!id) return null;
  const response = await axios.get<Job>(`${marketplaceAPIURI}/api/jobs/${id}`);
  return response.data;
};

export const validatePostcode = async (
  axios: AxiosInstance,
  { postcode }: ValidatePostcodeInput,
) => {
  const response = await axios.post<boolean>(
    `${marketplaceAPIURI}/api/jobs/validate-postcode/${postcode}`,
  );
  return response.data;
};

export const getJobsheet = async (
  axios: AxiosInstance,
  { jobId, tradespersonId }: GetJobsheetInput,
) => {
  const response = await axios.get<JobSheet>(
    `${marketplaceAPIURI}/api/jobsheet?jobId=${jobId}&tradespersonId=${tradespersonId}`,
  );
  return response.data;
};

export const getJobsheetReport = async (axios: AxiosInstance, params: { id: string }) => {
  if (!params.id) return null;
  const response = await axios.get<string>(
    `${marketplaceAPIURI}/api/jobsheet/${params.id}/report`,
    {},
  );
  return response.data;
};

export const getJobs = async (axios: AxiosInstance, params: GetJobsInput) => {
  const queryString = getQueryString(params);
  const response = await axios.get<JobsResponse>(`${marketplaceAPIURI}/api/jobs?${queryString}`);
  return response.data;
};

export const getJobEvents = async (axios: AxiosInstance, params: GetJobEventsInput) => {
  const queryString = getQueryString(params);
  const response = await axios.get<JobsEventsResponse>(
    `${marketplaceAPIURI}/api/jobs/events?${queryString}`,
  );
  return response.data;
};

export const createJob = async (axios: AxiosInstance, params: JobInput) => {
  const response = await axios.post<Job>(`${marketplaceAPIURI}/api/jobs`, params).catch((err) => {
    if (err instanceof AxiosError && err.response?.status === 400) {
      window.alert(err.request.response);
    }

    throw err;
  });
  return response.data;
};

export const updateJob = async (axios: AxiosInstance, params: UpdateJobArgs) => {
  const response = await axios
    .patch<Job>(`${marketplaceAPIURI}/api/jobs/${params.id}`, params.input)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const updateJobSheet = async (axios: AxiosInstance, params: UpdateJobSheetArgs) => {
  const response = await axios
    .patch<Job>(`${marketplaceAPIURI}/api/jobsheet/${params.id}`, params.input)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const addJobNote = async (
  axios: AxiosInstance,
  { jobId, note, shouldAddNoteToTp }: { jobId: string; note: string; shouldAddNoteToTp: boolean },
) => {
  const response = await axios.post<JobEvent>(`${marketplaceAPIURI}/api/jobs/events`, {
    jobId,
    note,
    shouldAddNoteToTp,
  });
  return response.data;
};

export const setJobForAutoAllocate = async (
  axios: AxiosInstance,
  params: { referenceId: string },
) => {
  const response = await axios
    .post<boolean>(`${marketplaceAPIURI}/api/jobs/${params.referenceId}/set-job-for-auto-allocate`)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const invokeJobsheetSubmit = async (
  axios: AxiosInstance,
  params: { referenceId: string },
) => {
  const response = await axios
    .post<boolean>(`${marketplaceAPIURI}/api/jobs/${params.referenceId}/invoke-jobsheet-submit`)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const sendSoftBookingConfirmationComm = async (
  axios: AxiosInstance,
  params: { referenceId: string },
) => {
  const response = await axios
    .post<boolean>(
      `${marketplaceAPIURI}/api/jobs/${params.referenceId}/send-soft-booking-confirmation-comm`,
    )
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export async function requireJobsheetApproval(
  axios: AxiosInstance,
  params: { jobReferenceId: string },
) {
  const response = await axios
    .post<boolean>(
      `${marketplaceAPIURI}/api/jobs/${params.jobReferenceId}/require-jobsheet-approval`,
    )
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
}

export async function approveJobsheet(axios: AxiosInstance, params: { jobReferenceId: string }) {
  const response = await axios
    .post<boolean>(`${marketplaceAPIURI}/api/jobs/${params.jobReferenceId}/approve-jobsheet`)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
}

export const setJobAutoAllocation = async (
  axios: AxiosInstance,
  params: { referenceId: string; enabled: boolean },
) => {
  const response = await axios
    .patch<boolean>(`${marketplaceAPIURI}/api/jobs/${params.referenceId}/allocation`, {
      enabled: params.enabled,
    })
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const scheduleJob = async (axios: AxiosInstance, params: ScheduleJobArgs) => {
  const response = await axios
    .post<Job>(`${marketplaceAPIURI}/api/jobs/${params.id}/schedule`, params.input)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const pinJob = async (axios: AxiosInstance, params: PinJobArgs) => {
  const response = await axios
    .post<Job>(`${marketplaceAPIURI}/api/jobs/${params.id}/pin`, params.input)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

type DeleteJobParams = {
  id: string;
  reason: string;
  residentAction: CancellationResidentAction | null;
};

export const deleteJob = async (axios: AxiosInstance, params: DeleteJobParams) => {
  const queryString = getQueryString({
    reason: "unallocated_by_partner",
    note: params.reason,
    resident_comm_action: params.residentAction,
  });
  await axios.delete(`${marketplaceAPIURI}/api/jobs/${params.id}?${queryString}`);
};

export const initiateRecall = async (
  axios: AxiosInstance,
  params: { id: string; jobType: string },
) => {
  const response = await axios.post(`${marketplaceAPIURI}/api/jobs/${params.id}/initiate-recall`, {
    jobType: params.jobType,
  });
  return response.data;
};

export const unallocateJob = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.patch(`${marketplaceAPIURI}/api/jobs/${params.id}/unallocate`);
  return response.data;
};

export const restartJobAutoAllocation = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.post(
    `${marketplaceAPIURI}/api/jobs/${params.id}/restart-auto-allocation`,
  );
  return response.data;
};

export const getInvoices = async (axios: AxiosInstance, params: GetInvoicesInput) => {
  const queryString = getQueryString(params);
  const response = await axios.get<InvoicesResponse>(
    `${marketplaceAPIURI}/api/invoices?${queryString}`,
  );
  return response.data;
};

export const getInvoice = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.get<Invoice>(
    `${multiservicesURI}/api/partner/v2/invoicing/invoice/${params.id}`,
  );
  return response.data;
};

export const getInvoiceDetailsForJob = async (
  axios: AxiosInstance,
  { jobId, tradespersonId }: GetInvoiceDetailsForJobInput,
) => {
  const response = await axios.get<InvoicingDetails>(
    `${marketplaceAPIURI}/api/invoices/invoice-details-for-job?jobId=${jobId}&tradespersonId=${tradespersonId}`,
  );
  return response.data;
};

export const updateInvoice = async (axios: AxiosInstance, params: UpdateInvoiceArgs) => {
  const response = await axios
    .patch<Invoice>(`${marketplaceAPIURI}/api/invoices/v2/${params.id}`, params.input)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const deleteInvoice = async (axios: AxiosInstance, params: DeleteInvoiceArgs) => {
  const response = await axios
    .delete<unknown>(`${marketplaceAPIURI}/api/invoices/${params.id}`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const markInvoicesAsPaid = async (axios: AxiosInstance, params: { ids: string[] }) => {
  const response = await axios.post<boolean>(
    `${marketplaceAPIURI}/api/invoices/mark-paid`,
    params.ids,
  );
  return response.data;
};

const CSVUploadViaS3 = async (
  axios: AxiosInstance,
  requestURL: string,
  params: { file: File },
  triggerURL: string,
) => {
  const response = await axios.post<UploadRequestResponse>(requestURL, { name: params.file.name });
  const signedFields = response.data.signedFields;

  const formData = new FormData();
  formData.append("Content-Type", params.file.type);

  Object.entries(signedFields).forEach(([k, v]) => {
    formData.append(k, v);
  });

  formData.append("file", params.file); // The file has to be the last element !!

  const uploadUrl = response.data.uploadUrl;

  await axiosNoAuth.post(uploadUrl, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  await axios.post<void>(triggerURL);
};

export const uploadInvoiceReportCSV = async (axios: AxiosInstance, params: { file: File }) => {
  await CSVUploadViaS3(
    axios,
    `${marketplaceAPIURI}/api/invoices/upload-csv-request`,
    params,
    `${marketplaceAPIURI}/api/invoices/initiate-recon-file-import`,
  );
};

export const uploadTradespeopleUpdateCSV = async (axios: AxiosInstance, params: { file: File }) => {
  await CSVUploadViaS3(
    axios,
    `${multiservicesURI}/api/partner/v3/tradespersons/upload-csv-request`,
    params,
    `${multiservicesURI}/api/partner/v3/tradespersons/trigger-tp-bulk-update`,
  );
};

export const uploadJobsCSV = async (axios: AxiosInstance, params: { file: File }) => {
  await CSVUploadViaS3(
    axios,
    `${multiservicesURI}/api/me/v1/jobs/upload-csv-request`,
    params,
    `${multiservicesURI}/api/me/v1/jobs/trigger-import-jobs`,
  );
};

export const getTradespersonPartners = async (
  axios: AxiosInstance,
  params: GetTradespeopleInput,
): Promise<GetTradespeopleResponse> => {
  const queryString = getQueryString(params);
  const response = await axios.get<GetTradespeopleResponse>(
    `${marketplaceAPIURI}/api/tradespeople?${queryString}`,
  );

  return response.data;
};

export const getTradespersonPartnersV4 = async (
  axios: AxiosInstance,
  params: GetTradespeopleInput,
): Promise<GetTradespeopleResponse> => {
  const queryString = getQueryString(params);
  const response = await axios.get<GetTradespeopleResponse>(
    `${multiservicesURI}/api/partner/v4/tradespersons?${queryString}`,
  );

  return response.data;
};

export const getTradesperson = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.get<Tradesperson>(
    `${multiservicesURI}/api/partner/v3/tradespersons/${params.id}`,
  );

  return response.data;
};

export const listTradespersons = async (
  axios: AxiosInstance,
  params: {
    page?: number;
    pageSize?: number;
    query?: string;
  },
) => {
  const queryString = getQueryString(params);

  const response = await axios.get<GetTradespersonsResponse>(
    `${multiservicesURI}/api/partner/v3/tradespersons?${queryString}`,
  );

  return response.data;
};

export const getQuote = async (
  axios: AxiosInstance,
  { jobId, tradespersonId, companyId }: GetQuoteInput,
) => {
  let url = `${multiservicesURI}/api/partner/v1/invoicing/quotes?job_id=${jobId}`;
  if (companyId) {
    url += `&company_id=${companyId}`;
  } else {
    url += `&tradesperson_id=${tradespersonId}`;
  }
  const response = await axios.get<Quote[]>(url);
  const data = response.data;
  if (data && data.length > 0) {
    return data[0];
  }
  throw new Error("Quote not found");
};

export const getTradespersonPartner = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.get<PartnerTradesperson>(
    `${marketplaceAPIURI}/api/tradespeople/${params.id}`,
  );
  return response.data;
};

/**
 * Get a tradesperson partner (TTP) via a partner id and a tradesperson id (TP)
 */
export const getTradespersonByPartnerTradesperson = async (
  axios: AxiosInstance,
  params: { partnerId: string; tradespersonId: string },
) => {
  const response = await axios.get<PartnerTradesperson>(
    `${marketplaceAPIURI}/api/tradespeople-by-partner-tradesperson/${params.partnerId}/${params.tradespersonId}`,
  );
  return response.data;
};

export const updateTradesperson = async (axios: AxiosInstance, params: UpdateTradespersonArgs) => {
  const response = await axios
    .patch<PartnerTradesperson>(`${marketplaceAPIURI}/api/tradespeople/${params.id}`, params.input)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      if (err.response.status === 409) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const impersonateTradesperson = async (
  axios: AxiosInstance,
  params: { email_address: string },
) => {
  const response = await axios
    .post<ImpersonateTradespersonResponse>(
      `${multiservicesURI}/api/partner/v1/impersonate-tradesperson`,
      params,
    )
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const updateQuote = async (axios: AxiosInstance, params: UpdateQuoteArgs) => {
  const response = await axios
    .patch<Quote>(`${marketplaceAPIURI}/api/quotes/${params.id}`, params.input)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const sendQuoteToResident = async (axios: AxiosInstance, params: { quoteId: string }) => {
  const response = await axios
    .post<Quote>(`${marketplaceAPIURI}/api/quotes/${params.quoteId}/forward-to-resident`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const declineQuote = async (axios: AxiosInstance, params: { quoteId: string }) => {
  const response = await axios
    .post<Quote>(`${marketplaceAPIURI}/api/quotes/${params.quoteId}/decline`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const acceptQuote = async (axios: AxiosInstance, params: { quoteId: string }) => {
  const response = await axios
    .post<Quote>(`${marketplaceAPIURI}/api/quotes/${params.quoteId}/accept`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const getAppointments = async (axios: AxiosInstance, params: GetAppointmentsInput) => {
  const queryString = getQueryString(params);
  const response = await axios.get<Appointment[]>(
    `${marketplaceAPIURI}/api/appointments?${queryString}`,
  );
  return response.data;
};

export const getAppointment = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.get<Appointment>(
    `${marketplaceAPIURI}/api/appointments/${params.id}`,
  );
  return response.data;
};

export const deleteAppointment = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.delete<unknown>(
    `${marketplaceAPIURI}/api/appointments/${params.id}`,
  );
  return response.data;
};

export const getAvailability = async (axios: AxiosInstance, params: GetAvailabilityInput) => {
  const response = await axios.post<GetAvailabilityResponse>(
    `${marketplaceAPIURI}/api/appointments/availability`,
    params,
  );
  return response.data;
};

export const getTradespersonEvents = async (
  axios: AxiosInstance,
  params: GetTradespersonEventsInput,
) => {
  const queryString = getQueryString(params);
  const response = await axios.get<TradespersonEventsResponse>(
    `${marketplaceAPIURI}/api/tradesperson/events?${queryString}`,
  );
  return response.data;
};

export const getTradespersonCompanyEvents = async (
  axios: AxiosInstance,
  params: GetTradespersonEventsInput,
) => {
  const query = {
    tradesperson_id: params.tradespersonId,
    page: params.page,
    page_size: params.pageSize,
  };
  const queryString = getQueryString(query);
  const response = await axios.get<TradespersonEventsResponse>(
    `${multiservicesURI}/api/partner/v1/tradesperson/events?${queryString}`,
  );
  return response.data;
};

export const blacklistTradespersonPartner = async (
  axios: AxiosInstance,
  params: { id: string; input: { isBlacklisted: boolean; blacklistReason?: string } },
) => {
  const response = await axios
    .post<PartnerTradesperson>(
      `${marketplaceAPIURI}/api/tradespeople/${params.id}/blacklist`,
      params.input,
    )
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }

      throw err;
    });
  return response.data;
};

export const addTradespersonNote = async (
  axios: AxiosInstance,
  { tradespersonId, note }: { tradespersonId: string; note: string },
) => {
  const response = await axios.post<TradespersonEvent>(
    `${multiservicesURI}/api/partner/v1/tradesperson/events/tradesperson-note`,
    {
      tradesperson_id: tradespersonId,
      note,
    },
  );
  return response.data;
};

export const getContact = async (axios: AxiosInstance, params: { referenceId: string }) => {
  const response = await axios.get<Contact>(
    `${marketplaceAPIURI}/api/contact/${params.referenceId}`,
  );
  return response.data;
};

export const getManagedRates = async (axios: AxiosInstance) => {
  const response = await axios.get<ManagedRate[]>(`${marketplaceAPIURI}/api/managed-rates`);
  return response.data;
};

export const getPartnerDomains = async (axios: AxiosInstance) => {
  const response = await axios.get<PartnerDomain[]>(`${marketplaceAPIURI}/api/partner-domains`);
  return response.data;
};

export const getTags = async (axios: AxiosInstance) => {
  const response = await axios.get<Tag[]>(`${marketplaceAPIURI}/api/tags`);
  return response.data;
};

export const getEntityTagsCount = async (
  axios: AxiosInstance,
  params: {
    jobId?: string;
    tradespersonId?: string;
    invoiceId?: string;
    companyId?: string;
    source?: TagEntityType;
  },
) => {
  const queryString = getQueryString(params);
  const response = await axios.get<EntityTagCount[]>(
    `${marketplaceAPIURI}/api/entity-tags/tags-count?${queryString}`,
  );
  return response.data;
};

export const getEntityTags = async (
  axios: AxiosInstance,
  params: {
    jobId?: string;
    tradespersonId?: string;
    invoiceId?: string;
    companyId?: string;
    tagId?: string;
    source?: TagEntityType;
  },
) => {
  const queryString = getQueryString(params);
  const response = await axios.get<EntityTag[]>(
    `${marketplaceAPIURI}/api/entity-tags?${queryString}`,
  );
  return response.data;
};

export const getPartner = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.get<Partner>(`${marketplaceAPIURI}/api/partners/${params.id}`);
  return response.data;
};

export const getPartners = async (axios: AxiosInstance) => {
  const response = await axios.get<Partner[]>(`${marketplaceAPIURI}/api/partners`);
  return response.data;
};

export const getProducts = async (axios: AxiosInstance) => {
  const response = await axios.get<Product[]>(`${multiservicesURI}/api/partner/v1/products`);
  return response.data;
};

export const getProduct = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios.get<Product>(
    `${multiservicesURI}/api/partner/v1/products/${params.id}`,
  );
  return response.data;
};

export const createManagedRate = async (axios: AxiosInstance, params: Partial<ManagedRate>) => {
  const response = await axios
    .post<ManagedRate>(`${marketplaceAPIURI}/api/managed-rates`, params)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const updateManagedRate = async (axios: AxiosInstance, params: Partial<ManagedRate>) => {
  const response = await axios
    .patch<ManagedRate>(`${marketplaceAPIURI}/api/managed-rates/${params.id}`, params)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const deleteManagedRate = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios
    .delete<boolean>(`${marketplaceAPIURI}/api/managed-rates/${params.id}`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export async function markJobAsDone(axios: AxiosInstance, params: { jobReferenceId: string }) {
  const response = await axios.post<void>(
    `${marketplaceAPIURI}/api/jobs/${params.jobReferenceId}/mark-as-done`,
  );
  return response.data;
}

export async function flagInvoice(
  axios: AxiosInstance,
  params: { invoiceReferenceId: string },
): Promise<Invoice> {
  const response = await axios.post<Invoice>(
    `${marketplaceAPIURI}/api/invoices/${params.invoiceReferenceId}/flag`,
  );

  return response.data;
}

export async function unflagInvoice(
  axios: AxiosInstance,
  params: { invoiceReferenceId: string },
): Promise<Invoice> {
  const response = await axios.post<Invoice>(
    `${marketplaceAPIURI}/api/invoices/${params.invoiceReferenceId}/unflag`,
  );

  return response.data;
}

export const addOrRemovePeopleFromJob = async (
  axios: AxiosInstance,
  params: {
    jobRefId: string;
    payload: {
      eventType: AddOrRemovePeopleEventType;
      initials: string;
    };
  },
) => {
  const response = await axios
    .patch<
      string[]
    >(`${marketplaceAPIURI}/api/jobs/${params.jobRefId}/add-or-remove-people-from-job`, params.payload)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const createEntityTags = async (axios: AxiosInstance, params: Partial<EntityTag>[]) => {
  const response = await axios
    .post<EntityTag>(`${marketplaceAPIURI}/api/entity-tags`, params)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const deleteEntityTag = async (axios: AxiosInstance, params: { entityTagId: string }) => {
  const response = await axios
    .delete<boolean>(`${marketplaceAPIURI}/api/entity-tags/${params.entityTagId}`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const createTag = async (axios: AxiosInstance, params: Partial<Tag>) => {
  const response = await axios.post<Tag>(`${marketplaceAPIURI}/api/tags`, params).catch((err) => {
    if (err.response.status === 400) {
      window.alert(err.request.response);
    }
    throw err;
  });
  return response.data;
};

export const updateTag = async (axios: AxiosInstance, params: Partial<ManagedRate>) => {
  const response = await axios
    .patch<Tag>(`${marketplaceAPIURI}/api/tags/${params.id}`, params)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const deleteTag = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios
    .delete<boolean>(`${marketplaceAPIURI}/api/tags/${params.id}`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const getCompany = async (axios: AxiosInstance, params: { id: string }) => {
  const response = await axios
    .get<Company>(`${multiservicesURI}/api/partner/v1/companies/${params.id}`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const listCompanies = async (
  axios: AxiosInstance,
  params: {
    query?: string | null | undefined;
    page?: number | null | undefined;
    page_size?: number | null | undefined;
    partner_id?: string | null | undefined;
  },
) => {
  const queryString = getQueryString(params);

  const response = await axios
    .get<GetCompaniesResponse>(`${multiservicesURI}/api/partner/v1/companies?${queryString}`)
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const createCompany = async (axios: AxiosInstance, params: CreateCompany) => {
  const response = await axios
    .post<Company>(`${multiservicesURI}/api/partner/v1/companies`, params)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }

      throw err;
    });
  return response.data;
};

export const updateCompany = async (
  axios: AxiosInstance,
  params: { id: string; data: UpdateCompany },
) => {
  const response = await axios
    .patch<Company>(`${multiservicesURI}/api/partner/v1/companies/${params.id}`, params.data)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }

      throw err;
    });
  return response.data;
};

export const listCompanyPartners = async (
  axios: AxiosInstance,
  params: { company_id: string; page?: number; pageSize?: number },
) => {
  const queryString = getQueryString(params);

  const response = await axios
    .get<GetCompanyPartnersResponse>(
      `${multiservicesURI}/api/partner/v1/company-partners?${queryString}`,
    )
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });
  return response.data;
};

export const updateCompanyPartner = async (
  axios: AxiosInstance,
  params: { id: string; data: UpdateCompanyPartner },
) => {
  const response = await axios
    .patch<CompanyPartner>(
      `${multiservicesURI}/api/partner/v1/company-partners/${params.id}`,
      params.data,
    )
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }

      throw err;
    });
  return response.data;
};

export const createCompanyPartner = async (
  axios: AxiosInstance,
  params: CreateCompanyPatner,
): Promise<CompanyPartner> => {
  const response = await axios
    .post<CompanyPartner>(`${multiservicesURI}/api/partner/v1/company-partners`, params)
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }

      throw err;
    });
  return response.data;
};

export const listTradespersonCompanies = async (
  axios: AxiosInstance,
  params: {
    page?: number;
    pageSize?: number;
    company_id?: string | null | undefined;
    group_id?: number | null | undefined;
  },
) => {
  const queryString = getQueryString(params);

  const response = await axios
    .get<GetTradespersonCompaniesResponse>(
      `${multiservicesURI}/api/partner/v1/tradesperson-companies?${queryString}`,
    )
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      throw err;
    });

  return response.data;
};

export const getTradespersonCompany = async (
  axios: AxiosInstance,
  params: { id: string },
): Promise<TradespersonCompany> => {
  const response = await axios
    .get<TradespersonCompany>(
      `${multiservicesURI}/api/partner/v1/tradesperson-companies/${params.id}`,
    )
    .catch((err) => {
      if (err.response.status === 400) {
        window.alert(err.request.response);
      }
      if (err.response.status === 404) {
        window.alert(err.request.response);
      }
      throw err;
    });

  return response.data;
};

export const createTradespersonCompany = async (
  axios: AxiosInstance,
  params: CreateTradespersonCompany,
) => {
  const response = await axios.post<TradespersonCompany>(
    `${multiservicesURI}/api/partner/v1/tradesperson-companies`,
    params,
  );
  return response.data;
};

export const updateTradespersonCompany = async (
  axios: AxiosInstance,
  params: { id: string; data: UpdateTradespersonCompany },
) => {
  const response = await axios
    .patch<TradespersonCompany>(
      `${multiservicesURI}/api/partner/v1/tradesperson-companies/${params.id}`,
      params.data,
    )
    .catch((err) => {
      if (err instanceof AxiosError && err.response?.status === 400) {
        window.alert(err.request.response);
      }

      throw err;
    });
  return response.data;
};

export const listPermissionGroups = async (axios: AxiosInstance) => {
  const response = await axios.get<PermissionGroup[]>(
    `${multiservicesURI}/api/partner/v1/auth/groups`,
  );

  return response.data;
};

// Appropriate type as the function accepts any non-nullish value and does runtime reflection on it
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
function getQueryString(params: Record<string, any>) {
  const nonEmptyParams = Object.fromEntries(
    Object.entries(params)
      .map(([key, value]) =>
        value instanceof Array && value.length === 0 ? [key, null] : [key, value],
      )
      .filter(([_, value]) => isNotNullish(value)),
  );

  const searchParams = new URLSearchParams(nonEmptyParams as Record<string, string>);

  return searchParams?.toString() ?? "";
}
